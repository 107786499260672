import './product-highlight-teaser.scss';

// import { Slider } from './../../components/slider/slider';
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper';

class ProductHighlightTeaser {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-producthighlightteaser',
            sliderAttr: 'slider',
            slideAttr: 'slide'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$productHighlightTeaser = $element;
        this.$productHighlightTeaserSlider = this.$productHighlightTeaser.querySelector('[' + this.settings.initAttr + '="' + this.settings.sliderAttr + '"]');
        this.$productHighlightTeaserSlides = this.$productHighlightTeaser.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.slideAttr + '"]');
        this.productHighlightTeaserSlider = null;

        if (this.$productHighlightTeaserSlides.length > 1) {
            import(
                /* webpackChunkName: "slider/slider.js" */
                './../../components/slider/slider').then(({ Slider }) => {
                this.Slider = Slider;
                this.initialize();
            });
        }
        // this.initialize();
    }

    initialize () {
        console.log('ProductHighlightTeaser');
        this.initSlider();
    }

    initSlider () {
        this.productHighlightTeaserSlider = new this.Slider(this.$productHighlightTeaserSlider, {
            modules: [A11y, Navigation, Pagination, Scrollbar],
            autoHeight: true,
            speed: 500,
            slidesPerView: 'auto',
            centeredSlides: true,
            fractionText: '•',
            scrollbar: true,
            scrollbarAppendToControls: true,
            counter: true,
            counterCustom: true,
            initAttr: this.settings.initAttr,
            onInit: () => {
                if (window.allowAnimation === true) {
                    this.$productHighlightTeaser.setAttribute('data-inview', '');
                    window.checkInview(window.eventScroller);
                }
            }
        });
    }
}

export { ProductHighlightTeaser };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$productHighlightTeaser = $context.querySelectorAll('[data-producthighlightteaser="root"]');
        for (let i = 0; i < $$productHighlightTeaser.length; i++) {
            const $productHighlightTeaser = $$productHighlightTeaser[i];

            const productHighlightTeaserAPI = new ProductHighlightTeaser($productHighlightTeaser);
            $productHighlightTeaser.API = productHighlightTeaserAPI;
        }
    }
});
